<template>
  <div>
    <iframe :src='frame' width='100%' height='1200px' frameborder='0'></iframe>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import prints from '@/api/prints'
import { i18n } from '@/plugins/i18n'
import font from '@/assets/fonts/font.json'
import fontBold from '@/assets/fonts/fontBold.json'
import { formatPrice, sumdate } from '@/plugins/filters'
import vatDataPrint from '@/@fake-db/data/vatDataPrint.json'
import 'jspdf-autotable'
import shop from '@/api/systemSetting/shop'

export default {
  setup() {
    const { route } = useRouter()
    const vatList = ref(vatDataPrint.data)
    const fonts = ref(font)
    const fontsBold = ref(fontBold)
    const dataTableList = ref([])
    const fontTitle = ref(13)
    const fontBody = ref(12)
    const lastTable = ref(0)
    const shop_print_type = ref(0)
    const shopData = ref({})
    const frame = ref('')
    const receiptData = ref({})
    const point = ref(route.value.query.point)
    const comment = ref(route.value.query.showComment)

    i18n.locale = route.value.query.lang == '2' ? 'en' : 'th'
    const printsOrder = () => {
      prints
        .printsInvoice({
          order_id_pri: route.value.query.id,
          orderpay_id: route.value.query.orderPayId,
          lang: i18n.locale,
        })
        .then(res => {
          dataTableList.value = res.orderdetail
          receiptData.value = res
          savePDF(res)
        })
    }
    shop.shopGet().then(res => {
      shop_print_type.value = route.value.query.shop_print_type
      shopData.value = res
      printsOrder()
    })
    const generateData = () => {
      const result = []
      let drugTotal = 0
      let drugDiscount = 0
      let count = 0
      let priceUnit = 0
      let name = ''

      dataTableList.value.forEach((item, index) => {
        // drug
        if (route.value.query.showDrugStatus == 'true' && route.value.query.showCourseStatus == 'false') {
          if (item.orderdetail_type_id == '2') {
            count += 1
            drugTotal += +item.orderdetail_total.replaceAll(',', '')
            drugDiscount += +item.orderdetail_discount.replaceAll(',', '')
            priceUnit += +item.orderdetail_price.replaceAll(',', '')
            name = i18n.t('drug_and_service')
          } else {
            result.push([
              `${item.orderdetail_name} (${item.orderdetail_id})`,
              `${item.orderdetail_amount}`,
              item.orderdetail_unit == 'null' || null ? i18n.t('times') : item.orderdetail_unit,
              item.orderdetail_price,
              item.orderdetail_discount,
              item.orderdetail_total,
            ])
          }
          if (index + 1 == dataTableList.value.length && count != 0) {
            result.push([
              `${name}`,
              '1.00',
              i18n.t('times'),
              formatPrice(drugTotal),
              formatPrice(drugDiscount),
              formatPrice(drugTotal),
            ])
          }
        }

        // check and course
        if (route.value.query.showCourseStatus == 'true' && route.value.query.showDrugStatus == 'false') {
          if (item.orderdetail_type_id == '1' || item.orderdetail_type_id == '3') {
            count += 1
            drugTotal += +item.orderdetail_total.replaceAll(',', '')
            drugDiscount += +item.orderdetail_discount.replaceAll(',', '')
            priceUnit += +item.orderdetail_price.replaceAll(',', '')
            name = i18n.t('service_and_treatment_fees')
          } else {
            result.push([
              `${item.orderdetail_name} (${item.orderdetail_id})`,
              `${item.orderdetail_amount}`,
              item.orderdetail_unit == 'null' || null ? i18n.t('times') : item.orderdetail_unit,
              item.orderdetail_price,
              item.orderdetail_discount,
              item.orderdetail_total,
            ])
          }
          if (index + 1 == dataTableList.value.length && count != 0) {
            result.push([
              `${name}`,
              '1.00',
              i18n.t('times'),
              formatPrice(drugTotal),
              formatPrice(drugDiscount),
              formatPrice(drugTotal),
            ])
          }
        }

        // all check
        if (route.value.query.showCourseStatus == 'true' && route.value.query.showDrugStatus == 'true') {
          if (item.orderdetail_type_id == '1' || item.orderdetail_type_id == '2' || item.orderdetail_type_id == '3') {
            count += 1
            drugTotal += +item.orderdetail_total.replaceAll(',', '')
            drugDiscount += +item.orderdetail_discount.replaceAll(',', '')
            priceUnit += +item.orderdetail_price.replaceAll(',', '')
            name = i18n.t('drug_and_treatment_fees')
          } else {
            result.push([
              `${item.orderdetail_name} (${item.orderdetail_id})`,
              `${item.orderdetail_amount}`,
              item.orderdetail_unit == 'null' || null ? i18n.t('times') : item.orderdetail_unit,
              item.orderdetail_price,
              item.orderdetail_discount,
              item.orderdetail_total,
            ])
          }
          if (index + 1 == dataTableList.value.length && count != 0) {
            result.push([
              `${name}`,
              '1.00',
              i18n.t('times'),
              receiptData.value.order_price,
              formatPrice(drugDiscount),
              receiptData.value.order_price,
            ])
          }
        }

        // bold
        if (route.value.query.showDrugStatus == 'false' && route.value.query.showCourseStatus == 'false') {
          result.push([
            `${item.orderdetail_name} (${item.orderdetail_id})`,
            `${item.orderdetail_amount}`,
            item.orderdetail_unit == 'null' || null ? i18n.t('times') : item.orderdetail_unit,
            item.orderdetail_price,
            item.orderdetail_discount,
            item.orderdetail_total,
          ])
        }
      })
      result.map((item, index) => (item[0] = `${index + 1}) ${item[0]}`))

      return result
    }

    const headers = [
      `${i18n.t('list')}`,
      i18n.t('amount'),
      i18n.t('unit'),
      i18n.t('price_unit'),
      i18n.t('discount_unit'),
      i18n.t('total_amount'),
    ]

    const savePDF = data => {
      const doc = new jsPDF({
        orientation: 'landscape',
        format: 'a5',
      })
      doc.setProperties({
        title: `INVOICE ${data.order_id} - ${data.order_create}`,
      })
      doc.addFileToVFS('TH-Niramit-AS-bold.ttf', fonts.value.data)
      doc.addFileToVFS('TH-Niramit-AS-Bold-bold.ttf', fontsBold.value.data)
      doc.addFont('TH-Niramit-AS-bold.ttf', 'TH-Niramit', 'bold')
      doc.addFont('TH-Niramit-AS-Bold-bold.ttf', 'TH-Niramit', 'bold')
      doc.setFont('TH-Niramit', 'bold')

      doc.autoTable({
        theme: 'plain',
        styles: {
          font: 'TH-Niramit',
          fontStyle: 'bold',
          textColor: '#000',
          fontSize: fontBody.value,
          halign: 'left',
          cellPadding: 0.5,
          minCellHeight: -10,
        },
        showFoot: 'lastPage',
        headStyles: {
          textColor: '#000',
          fontStyle: 'bold',
          fontSize: fontTitle.value,
          halign: 'center',
        },
        columnStyles: {
          1: { halign: 'center', fillColor: '#F5F5F5', cellWidth: 15 },
          2: { halign: 'center', cellWidth: 16 },
          3: { halign: 'right', fillColor: '#F5F5F5', cellWidth: 20 },
          4: { halign: 'right', cellWidth: 26 },
          5: { halign: 'right', fillColor: '#F5F5F5', cellWidth: 18 },
        },
        startY: 52,
        margin: {
          top: 52,
          left: 5,
          right: 5,
          bottom: 45,
        },

        head: [headers],
        body: generateData(),

        didDrawPage(datax) {
          doc.addImage(JSON.parse(localStorage.getItem('shopImage')), 'JPEG', 7, 6, 28, 26)

          // Start Address Shop block
          if (shop_print_type.value == '1') {
            doc.setFontSize(fontTitle.value)
            doc.setFillColor('#000')
            doc.text(`${data.shop_name} ${data.shop_nature}`, 40, 10)
            doc.setFont('TH-Niramit', 'bold')
            doc.setFontSize(fontBody.value)
            doc.text(`${data.shop_address}  ${i18n.t('reportpsychotropic2_name_7')} ${data.shop_district}`, 40, 15)
            doc.text(
              `${i18n.t('reportpsychotropic2_name_8')} ${data.shop_amphoe} ${i18n.t('reportpsychotropic2_name_9')} ${
                data.shop_province
              } ${i18n.t('zip_code')} ${data.shop_zipcode}`,
              40,
              20,
            )
            doc.text(`${i18n.t('tel')} ${data.shop_tel} ${i18n.t('email')} ${data.shop_email}`, 40, 25)
            doc.text(`${i18n.t('numberLicenses')} ${data.shop_license} ${i18n.t('tax_id')} ${data.shop_tax}`, 40, 30)
            doc.setLineWidth(0.5)
            doc.setDrawColor('#000')
            doc.line(61, 34, 205, 34)
            doc.setDrawColor('#000')
            doc.line(4, 34, 60, 34)
          } else {
            doc.setFontSize(fontTitle.value)
            doc.setFillColor('#000')
            doc.text(`${shopData.value.shop_company_name}`, 40, 10)
            doc.setFont('TH-Niramit', 'bold')
            doc.setFontSize(fontBody.value)
            doc.text(
              `${shopData.value.shop_company_address}  ${i18n.t('reportpsychotropic2_name_7')} ${
                shopData.value.shop_company_district
              }`,
              40,
              15,
            )
            doc.text(
              `${i18n.t('reportpsychotropic2_name_8')} ${shopData.value.shop_company_amphoe} ${i18n.t(
                'reportpsychotropic2_name_9',
              )} ${shopData.value.shop_company_province} ${i18n.t('zip_code')} ${shopData.value.shop_company_zipcode}`,
              40,
              20,
            )
            doc.text(
              `${i18n.t('tel')} ${shopData.value.shop_company_tel} ${i18n.t('email')} ${
                shopData.value.shop_company_email
              }`,
              40,
              25,
            )
            doc.text(`${i18n.t('numberLicenses')} ${data.shop_license} ${i18n.t('tax_id')} ${data.shop_tax}`, 40, 30)
            doc.setLineWidth(0.5)
            doc.setDrawColor('#000')
            doc.line(61, 34, 205, 34)
            doc.setDrawColor('#000')
            doc.line(4, 34, 60, 34)
          }

          // End Address Shop block

          // start Address customer

          if (route.value.query.showCompany == 'false') {
            doc.setFont('TH-Niramit', 'bold')
            doc.setFontSize(fontTitle.value)
            doc.text(
              `${i18n.t('contacts')} ${data.customer_name} ${i18n.t('customer_code')} ${data.customer_id}`,
              5,
              40,
            )
            doc.setFont('TH-Niramit', 'bold')
            doc.setFontSize(fontBody.value)
            doc.text(`${i18n.t('tax_id')} ${data.customer_idcard}`, 204, 45, null, null, 'right')
            if (point.value == 'false') {
              doc.text(
                `${i18n.t('receive_points')} ${data.order_point} ${i18n.t('point')}  ${i18n.t(
                  'reward_points',
                )} ${formatPrice(data.customer_point || 0)} ${i18n.t('point')}`,
                204,
                50,
                null,
                null,
                'right',
              )
            }
            doc.text(
              `${data.customer_address || ''}${data.customer_address ? ' ' : ''}${i18n.t(
                'reportpsychotropic2_name_7',
              )} ${data.customer_district}`,
              5,
              45,
            )
            doc.text(
              `${i18n.t('reportpsychotropic2_name_8')} ${data.customer_amphoe} ${i18n.t(
                'reportpsychotropic2_name_9',
              )} ${data.customer_province} ${i18n.t('zip_code')} ${data.customer_zipcode} ${i18n.t('tel')} ${
                data.customer_tel
              }`,
              5,
              50,
            )
          } else {
            doc.setFont('TH-Niramit', 'bold')
            doc.setFontSize(fontTitle.value)
            doc.text(`${data.company_name}`, 5, 40)
            doc.setFont('TH-Niramit', 'bold')
            doc.setFontSize(fontBody.value)
            doc.text(
              `${i18n.t('contacts')} ${data.customer_name} ${i18n.t('customer_code')} ${data.customer_id}`,
              204,
              40,
              null,
              null,
              'right',
            )
            doc.text(`${i18n.t('tax_id')} ${data.company_tax}`, 204, 45, null, null, 'right')
            if (point.value == 'false') {
              doc.text(
                `${i18n.t('receive_points')} ${data.order_pointsave} ${i18n.t('point')}  ${i18n.t(
                  'reward_points',
                )} ${formatPrice(data.customer_point || 0)} ${i18n.t('point')}`,
                204,
                50,
                null,
                null,
                'right',
              )
            }
            doc.text(
              `${data.company_address || ''}${data.company_address ? ' ' : ''}${i18n.t('reportpsychotropic2_name_7')} ${
                data.company_district
              }`,
              5,
              45,
            )
            doc.text(
              `${i18n.t('reportpsychotropic2_name_8')} ${data.company_amphoe} ${i18n.t('reportpsychotropic2_name_9')} ${
                data.company_province
              } ${i18n.t('zip_code')} ${data.company_zipcode} ${i18n.t('tel')} ${data.company_tel}`,
              5,
              50,
            )
          }

          doc.setFont('TH-Niramit', 'bold')
          doc.setFontSize(fontTitle.value)
          doc.setTextColor('#000')
          doc.text(`${i18n.t('invoice2')}`, 204, 10, null, null, 'right')
          doc.text(`${i18n.t('invoice3')}`, 204, 15, null, null, 'right')
          if (data.order_status_id == '2') {
            doc.setFontSize(20)
            doc.setTextColor('#FF1744')
            doc.text(`(${i18n.t('cancel')})`, 204, 55, null, null, 'right')
          }
          doc.setDrawColor(0)
          doc.setFillColor('#000')

          // doc.rect(145, 17, 60, 16, 'F')

          doc.setFont('TH-Niramit', 'bold')
          doc.setFontSize(fontTitle.value)
          doc.text(`${i18n.t('a_number')} ${data.order_id}`, 204, 23, null, null, 'right')
          doc.text(`${i18n.t('date')} ${sumdate(data.order_create)}`, 204, 28, null, null, 'right')
          doc.setFont('TH-Niramit', 'bold')
          doc.setTextColor('#000')
          doc.setFontSize(fontTitle.value)

          doc.setLineWidth(0.1)
          doc.setDrawColor('#000')
          doc.line(4, 52, 205, 52)
          doc.line(4, 58, 205, 58)
          if (route.value.query.showPage == 'false') {
            const str = `${i18n.t('page')} ${doc.internal.getNumberOfPages()}`
            doc.setFontSize(10)
            doc.text(str, 197, 5)
          }
          lastTable.value = datax.cursor.y
          doc.setFontSize(fontBody.value)
          doc.setTextColor('#000')
          doc.text(
            `LINE ID : ${shopData.value.shop_line || ''} IG : ${shopData.value.shop_instagram || ''} FACEBOOK : ${
              shopData.value.shop_facebook || ''
            }`,
            5,
            288,
          )
          doc.setLineWidth(0.5)
          doc.setDrawColor('#000')
          doc.line(4, 290, 60, 290)
          doc.setDrawColor('#000')
          doc.line(61, 290, 205, 290)
        },
      })
      doc.setDrawColor(0)
      doc.setFillColor('#F5F5F5')
      doc.rect(100, lastTable.value + 5, 105, 8, 'F')
      doc.setFont('TH-Niramit', 'bold')
      doc.setFontSize(fontTitle.value)
      doc.setTextColor('#000')
      doc.text(data.net_amount_thai, 153, lastTable.value + 10, null, null, 'center')
      doc.setFontSize(fontBody.value)

      if (comment.value == 'false') {
        doc.text(`${i18n.t('note')} : ${data.order_comment.replaceAll('\n', ' ')}`, 4, lastTable.value + 45)
      }

      doc.text(
        `${shopData.value.shop_comment ? shopData.value.shop_comment.replaceAll('\n', ' ') : ''}`,
        4,
        lastTable.value + 50,
      )
      doc.setFontSize(fontTitle.value)

      const arr = [
        {
          name:
            route.value.query.lang == '2'
              ? vatList.value[+data.order_tax_id - 1].en
              : vatList.value[+data.order_tax_id - 1].th,
          value: data.order_tax,
        },
        { name: `${data.order_tax_id == '2' ? i18n.t('valueBeforeVat1') : i18n.t('valueBeforeVat')}`, value: data.order_total },
        { name: i18n.t('netTotalPrice'), value: data.net_amount },
        { name: `${i18n.t('period')} ${data.orderpay_period}`, value: data.orderpay_current },
        { name: `${i18n.t('balance')}`, value: data.orderpay_balance },
      ]
      const arr2 = [
        { name: `${data.order_tax_id == '2' ? i18n.t('valueBeforeVat') : i18n.t('valueBeforeVat1')}`, value: data.order_price },
        { name: i18n.t('discount'), value: data.order_save },
        { name: i18n.t('discount_points'), value: data.order_pointsave },
        { name: i18n.t('payment_status'), value: i18n.t('installment') },
        { name: i18n.t('totalPay'), value: data.orderpay_all },
      ]
      doc.text(`${i18n.t('paymentMethod')} : ${data.shop_bank_name}`, 4, lastTable.value + 40)
      for (let i = 0; i < arr.length; i++) {
        const x = 6
        if (arr[i].name == i18n.t('netTotalPrice')) {
          doc.setTextColor('#000')
        } else {
          doc.setTextColor('#000')
        }
        doc.text(arr[i].name, 50, lastTable.value + 10 + x * i, null, null, 'left')
        doc.text(arr[i].value, 95, lastTable.value + 10 + x * i, null, null, 'right')
      }
      doc.setTextColor('#000')
      for (let i = 0; i < arr2.length; i++) {
        const x = 6
        doc.text(`${arr2[i].name}`, 4, lastTable.value + 10 + x * i, null, null, 'left')
        doc.text(`${arr2[i].value}`, 45, lastTable.value + 10 + x * i, null, null, 'right')
      }

      doc.setFontSize(fontTitle.value)
      doc.setTextColor('#000')
      doc.text(i18n.t('userPrintDoc3'), 120, lastTable.value + 20, null, null, 'center')
      doc.text(i18n.t('userPrintDoc'), 180, lastTable.value + 20, null, null, 'center')

      doc.setLineWidth(0.2)
      doc.setDrawColor('#000')
      doc.line(100, lastTable.value + 30, 139, lastTable.value + 30)
      doc.line(160, lastTable.value + 30, 200, lastTable.value + 30)
      doc.text(sumdate(data.orderpay_date), 120, lastTable.value + 35, null, null, 'center')
      doc.text(sumdate(data.orderpay_date), 180, lastTable.value + 35, null, null, 'center')

      frame.value = doc.output('datauristring')
    }
    const go = () => {
      window.open(frame.value, '_blank')
    }

    return { savePDF, frame, go }
  },
}
</script>
